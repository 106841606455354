import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import OPERATION_TYPE_ENUM from 'norbr-shared-lib/constants/reconciliation/operationTypes/enum';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

export const matcherOrderListQuery = gql`
  query MatcherOrders($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    matcherOrders(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
      data
      count
    }
  }
`;

const useMatcherOrderListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(matcherOrderListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const { data: result, count: total } = useMemo(
    () => {
      if (error) return { data: [], count: 0 };
      const res = (loading ? previousData?.matcherOrders : data?.matcherOrders) ?? { data: [], count: 0 };
      return {
        data: res.data.map((order) => ({
          ...order,
          children: order.transactions.map((transaction) => ({
            ...transaction,
            // Fields order with displayed values from transactions
            [Fields.ORDER_ID]: transaction[Fields.TRANSACTION_ID],
            [Fields.ORDER_CREATION_DATE]: transaction[Fields.TRANSACTION_CREATION_DATE],
            [Fields.ORDER_AMOUNT]: transaction[Fields.TRANSACTION_AMOUNT],
            [Fields.ORDER_IS_RECONCILED]: transaction[Fields.TRANSACTION_IS_RECONCILED],
            [Fields.ORDER_RECONCILED_AMOUNT]: transaction[Fields.TRANSACTION_RECONCILED_AMOUNT],
            [Fields.ORDER_UNRECONCILED_AMOUNT]: transaction[Fields.TRANSACTION_UNRECONCILED_AMOUNT],
            [Fields.ORDER_RECONCILED_BALANCE]: transaction[Fields.TRANSACTION_RECONCILED_BALANCE],
            [Fields.ORDER_UNRECONCILED_BALANCE]: transaction[Fields.TRANSACTION_UNRECONCILED_BALANCE],
            [Fields.ORDER_TOTAL_BALANCE]: transaction[Fields.TRANSACTION_TOTAL_BALANCE],
            // Fields of operations - should be displayed as array values
            // OPERATION_TYPE - Merge CREDIT & REFUND
            [Fields.OPERATION_TYPE]: transaction.operation_type?.map((opType) =>
              opType === OPERATION_TYPE_ENUM.CAPTURE ? OPERATION_TYPE_ENUM.CAPTURE : OPERATION_TYPE_ENUM.REFUND,
            ),
            parent_id: order[Fields.ORDER_ID], // used to open drawer
          })),
        })),
        count: res.count,
      };
    },
    [data, loading, error],
  );

  useEffect(() => {
    fetch();
  }, [offset, limit]);

  return { result, total, loading, error };
};

export default useMatcherOrderListQuery;
