import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';

export const transactionListQuery = gql`
  query Transactions($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    transactions(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
      data
      count
    }
  }
`;

const useTransactionListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(transactionListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const { data: result, count: total } = useMemo(
    () => {
      if (error) return { data: [], count: 0 };
      return (loading ? previousData?.transactions : data?.transactions) ?? { data: [], count: 0 };
    },
    [data, loading, error],
  );

  useEffect(() => {
    fetch();
  }, [offset, limit]);

  return { result, total, loading, error };
};

export default useTransactionListQuery;
