import React from 'react';
import { Button, Form, Input, message, Modal, Radio, Space, Tag } from 'antd';
import MATCH_LATER_ENUM from 'norbr-shared-lib/constants/transaction/matchLaterReasons/enum';
import MATCH_LATER_LIST from 'norbr-shared-lib/constants/transaction/matchLaterReasons/list';
import { useApolloClient, useMutation } from '@apollo/client';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { delay } from '../../../../../../util/utils';
import { reconcileMutation } from '../query';
import styles from './MatcherModals.module.scss';

const MatchLaterModal = ({ operationId, onClose, isOpen }) => {
  const [form] = Form.useForm();
  const client = useApolloClient();
  const [reconcile, { loading }] = useMutation(reconcileMutation, {
    onCompleted: async () => {
      message.success(
        <span>
          The operation has been successfully tagged as <Tag>To match later</Tag> (status: <Tag>Pending</Tag>).
        </span>,
      );
      // Refetch queries with delay
      await delay(1000);
      await client.refetchQueries({ include: ['MatcherOrders', 'ActivityMatcher'] });
      onClose();
    },
  });

  return (
    <Modal
      className={styles.modal}
      title="MATCH LATER"
      closeIcon={<WindowCloseIcon />}
      open={isOpen}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose} disabled={loading}>
            CANCEL
          </Button>
          <Button type="primary" onClick={form.submit} loading={loading}>
            PENDING MATCH
          </Button>
        </div>
      }
      onCancel={onClose}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={({ reason, note }) =>
          reconcile({
            variables: {
              type: 'pending',
              operationId,
              reason,
              note,
            },
          })
        }
        initialValues={{
          reason: MATCH_LATER_ENUM.OTHER,
        }}
      >
        <Form.Item name="reason" label="Pending match reason">
          <Radio.Group>
            <Space direction="vertical">
              {MATCH_LATER_LIST.map(({ id, label }) => (
                <Radio key={id} value={id}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="note" label="Comment">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MatchLaterModal;
