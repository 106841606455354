import { ColorGenerator } from './colors';
import Units from './units';
import SeriesTypes from './seriesTypes';
import FieldTypes from '../../Common/constants/fieldTypes';
import { TargetEntity } from '../../Common/constants/targetEntities';

// /!\ items are sorted for most used /!\
enum Indicators {
  // --- Most used indicators
  authorizedOrders = 'authorized_orders',
  authorizedTurnover = 'authorized_turnover',
  averageBasket = 'average_basket',
  successRate = 'success_rate',
  refundedOrders = 'refunded_orders',
  // --- End of most used indicators
  refundedTransactions = 'refunded_transactions',
  allOrders = 'all_orders',
  allTransactions = 'all_transactions',
  authorizedTransactions = 'authorized_transactions',
  transactionSuccessRate = 'transaction_success_rate',
  transactionBlockRate = 'transaction_block_rate',
  transactionAuthenticationRate = 'transaction_authentication_rate',
  transactionFrictionlessRate = 'transaction_frictionless_rate',
  transactionRepeatRate = 'transaction_repeat_rate',
  transactionSavedRate = 'transaction_saved_rate',
  capturedOrders = 'captured_orders',
  capturedTransactions = 'captured_transactions',
  capturedTurnover = 'captured_turnover',
  captureRate = 'capture_rate',
  savedTurnover = 'saved_turnover',
  savedOrders = 'saved_orders',
  chargebackOrders = 'chargeback_orders',
  chargebackTransactions = 'chargeback_transactions',
  chargebackAmount = 'chargeback_amount',
  refundedAmount = 'refunded_amount',
  refundRate = 'refund_rate',
  blockedTransactions = 'blocked_transactions',
  authenticatedTransactions = 'authenticated_transactions',
  authenticatedOrders = 'authenticated_orders',
  frictionlessTransactions = 'frictionless_transactions',
  repeatedTransactions = 'repeated_transactions',
  savedTransactions = 'saved_transactions',
  singleCustomerEmail = 'single_customer_email',
  singleCustomerId = 'single_customer_id',
  transactionNetAmount = 'transaction_net_amount',
  transactionCosts = 'transaction_costs',
  emailSuccessRate = 'email_success_rate',
  customerIdSuccessRate = 'customer_id_success_rate',
  threeDsAuthorizedTransactions = 'three_ds_authorized_transactions',
  threeDsTransactions = 'three_ds_transactions',
  threeDsTransactionsSuccessRate = 'three_ds_transaction_success_rate',
  noThreeDsAuthorizedTransactions = 'no_three_ds_authorized_transactions',
  noThreeDsTransactions = 'no_three_ds_transactions',
  noThreeDsTransactionsSuccessRate = 'no_three_ds_transaction_success_rate',
  reconciledAmount = 'reconciled_amount',
  unreconciledAmount = 'unreconciled_amount',
  reconciledOrders = 'reconciled_orders',
  unreconciledOrders = 'unreconciled_orders',
  reconciledTransactions = 'reconciled_transactions',
  unreconciledTransactions = 'unreconciled_transactions',
  allCheckouts = 'all_checkouts',
  checkoutsWithOrder = 'checkouts_with_order',
  checkoutConversionRate = 'checkout_conversion_rate',
  checkoutsWithAuthorizedTransaction = 'checkouts_with_authorized_transaction',
  checkoutToAuthorizationRate = 'checkout_to_authorization_rate',
  allPayinOrders = 'all_payin_orders',
  allPayoutOrders = 'all_payout_orders',
  allPayinTransactions = 'all_payin_transactions',
  allPayoutTransactions = 'all_payout_transactions',
  allTickets = 'all_tickets',
  matchedTickets = 'matched_tickets',
  matchedTicketsExclWithdraw = 'matched_tickets_excl_withdraw',
  matchedAmount = 'matched_amount',
  matchedAmountExclWithdraw = 'matched_amount_excl_withdraw',
  unmatchedTickets = 'unmatched_tickets',
  unmatchedTicketsExclPending = 'unmatched_tickets_excl_pending',
  unmatchedAmount = 'unmatched_amount',
  unmatchedAmountExclPending = 'unmatched_amount_excl_pending',
  pendingTickets = 'pending_tickets',
  pendingAmount = 'pending_amount',
  withdrawTickets = 'withdraw_tickets',
  withdrawAmount = 'withdraw_amount',
  matchRate = 'match_rate',
  withdrawRate = 'withdraw_rate',
  pendingRate = 'pending_rate',
  allOperations = 'all_operations',
  manuallyMatchedTickets = 'manually_matched_tickets',
  automaticallyMatchedTickets = 'automatically_matched_tickets',
  allFinancialOperations = 'all_financial_operations',
}

const colorGenerator = new ColorGenerator({ startIndex: 10 });

export const config = {
  [Indicators.allOrders]: {
    id: Indicators.allOrders,
    label: 'All orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.allTransactions]: {
    id: Indicators.allTransactions,
    label: 'All transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.successRate]: {
    id: Indicators.successRate,
    label: 'Success rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.averageBasket]: {
    id: Indicators.averageBasket,
    label: 'Average basket',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.authorizedOrders]: {
    id: Indicators.authorizedOrders,
    label: 'Authorized orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.authorizedTransactions]: {
    id: Indicators.authorizedTransactions,
    label: 'Authorized transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.authorizedTurnover]: {
    id: Indicators.authorizedTurnover,
    label: 'Authorized turnover',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.capturedOrders]: {
    id: Indicators.capturedOrders,
    label: 'Captured orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.capturedTransactions]: {
    id: Indicators.capturedTransactions,
    label: 'Captured transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.capturedTurnover]: {
    id: Indicators.capturedTurnover,
    label: 'Capture turnover',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.captureRate]: {
    id: Indicators.captureRate,
    label: 'Capture rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.savedTurnover]: {
    id: Indicators.savedTurnover,
    label: 'Saved turnover',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.savedOrders]: {
    id: Indicators.savedOrders,
    label: 'Saved orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.refundedOrders]: {
    id: Indicators.refundedOrders,
    label: 'Refunded orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.refundedTransactions]: {
    id: Indicators.refundedTransactions,
    label: 'Refunded transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.refundedAmount]: {
    id: Indicators.refundedAmount,
    label: 'Refunded amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.refundRate]: {
    id: Indicators.refundRate,
    label: 'Refund rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.chargebackOrders]: {
    id: Indicators.chargebackOrders,
    label: 'Chargeback orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.chargebackTransactions]: {
    id: Indicators.chargebackTransactions,
    label: 'Chargeback transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.chargebackAmount]: {
    id: Indicators.chargebackAmount,
    label: 'Chargeback amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionAuthenticationRate]: {
    id: Indicators.transactionAuthenticationRate,
    label: 'Trx Authentication rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionBlockRate]: {
    id: Indicators.transactionBlockRate,
    label: 'Trx Blocked rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionRepeatRate]: {
    id: Indicators.transactionRepeatRate,
    label: 'Trx Repeat rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionSavedRate]: {
    id: Indicators.transactionSavedRate,
    label: 'Trx Saved rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionSuccessRate]: {
    id: Indicators.transactionSuccessRate,
    label: 'Trx Success rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionFrictionlessRate]: {
    id: Indicators.transactionFrictionlessRate,
    label: 'Trx Frictionless rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.blockedTransactions]: {
    id: Indicators.blockedTransactions,
    label: 'Blocked transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.authenticatedOrders]: {
    id: Indicators.authenticatedOrders,
    label: 'Authenticated orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.authenticatedTransactions]: {
    id: Indicators.authenticatedTransactions,
    label: 'Authenticated transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.frictionlessTransactions]: {
    id: Indicators.frictionlessTransactions,
    label: 'Frictionless transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.repeatedTransactions]: {
    id: Indicators.repeatedTransactions,
    label: 'Repeated transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.savedTransactions]: {
    id: Indicators.savedTransactions,
    label: 'Saved transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.singleCustomerEmail]: {
    id: Indicators.singleCustomerEmail,
    label: 'Single customer email',
    unit: Units.Customer,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.singleCustomerId]: {
    id: Indicators.singleCustomerId,
    label: 'Single customer ID',
    unit: Units.Customer,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.transactionNetAmount]: {
    id: Indicators.transactionNetAmount,
    label: 'Transaction net amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.transactionCosts]: {
    id: Indicators.transactionCosts,
    label: 'Transaction costs',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.emailSuccessRate]: {
    id: Indicators.emailSuccessRate,
    label: 'Email success rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.customerIdSuccessRate]: {
    id: Indicators.customerIdSuccessRate,
    label: 'Customer ID success rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.threeDsAuthorizedTransactions]: {
    id: Indicators.threeDsAuthorizedTransactions,
    label: '3DS authorized transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.threeDsTransactions]: {
    id: Indicators.threeDsTransactions,
    label: '3DS transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.threeDsTransactionsSuccessRate]: {
    id: Indicators.threeDsTransactionsSuccessRate,
    label: '3DS trx success rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.noThreeDsAuthorizedTransactions]: {
    id: Indicators.noThreeDsAuthorizedTransactions,
    label: 'No 3DS authorized transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.noThreeDsTransactions]: {
    id: Indicators.noThreeDsTransactions,
    label: 'No 3DS transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.noThreeDsTransactionsSuccessRate]: {
    id: Indicators.noThreeDsTransactionsSuccessRate,
    label: 'No 3DS trx success rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.reconciledAmount]: {
    id: Indicators.reconciledAmount,
    label: 'Reconciled amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.unreconciledAmount]: {
    id: Indicators.unreconciledAmount,
    label: 'Unreconciled amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.reconciledOrders]: {
    id: Indicators.reconciledOrders,
    label: 'Matched orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.unreconciledOrders]: {
    id: Indicators.unreconciledOrders,
    label: 'Unmatched orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.reconciledTransactions]: {
    id: Indicators.reconciledTransactions,
    label: 'Matched transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.unreconciledTransactions]: {
    id: Indicators.unreconciledTransactions,
    label: 'Unmatched transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.allCheckouts]: {
    id: Indicators.allCheckouts,
    label: 'All checkouts',
    unit: Units.Checkout,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.CHECKOUT,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.checkoutsWithOrder]: {
    id: Indicators.checkoutsWithOrder,
    label: 'Checkouts with order',
    unit: Units.Checkout,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.CHECKOUT,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.checkoutConversionRate]: {
    id: Indicators.checkoutConversionRate,
    label: 'Checkout conversion rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.CHECKOUT,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.checkoutsWithAuthorizedTransaction]: {
    id: Indicators.checkoutsWithAuthorizedTransaction,
    label: 'Checkouts with authorized transactions',
    unit: Units.Checkout,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.CHECKOUT,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.checkoutToAuthorizationRate]: {
    id: Indicators.checkoutToAuthorizationRate,
    label: 'Checkout to authorization rate',
    unit: Units.Percent,
    type: 'PERCENT',
    entity: TargetEntity.CHECKOUT,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
  [Indicators.allPayinOrders]: {
    id: Indicators.allPayinOrders,
    label: 'All payin orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.allPayoutOrders]: {
    id: Indicators.allPayoutOrders,
    label: 'All payout orders',
    unit: Units.Order,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.ORDER,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.allPayinTransactions]: {
    id: Indicators.allPayinTransactions,
    label: 'All payin transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.allPayoutTransactions]: {
    id: Indicators.allPayoutTransactions,
    label: 'All payout transactions',
    unit: Units.Transaction,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.TRANSACTION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.allTickets]: {
    id: Indicators.allTickets,
    label: 'All tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.matchedTickets]: {
    id: Indicators.matchedTickets,
    label: 'Matched tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.matchedTicketsExclWithdraw]: {
    id: Indicators.matchedTicketsExclWithdraw,
    label: 'Matched tickets excl. withdraw',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Indicators.matchedAmount]: {
    id: Indicators.matchedAmount,
    label: 'Matched amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.matchedAmountExclWithdraw]: {
    id: Indicators.matchedAmountExclWithdraw,
    label: 'Matched amount excl. withdraw',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Indicators.unmatchedTickets]: {
    id: Indicators.unmatchedTickets,
    label: 'Unmatched tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.unmatchedTicketsExclPending]: {
    id: Indicators.unmatchedTicketsExclPending,
    label: 'Unmatched tickets excl. pending',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.unmatchedAmount]: {
    id: Indicators.unmatchedAmount,
    label: 'Unmatched amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.unmatchedAmountExclPending]: {
    id: Indicators.unmatchedAmountExclPending,
    label: 'Unmatched amount excl. pending',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.pendingTickets]: {
    id: Indicators.pendingTickets,
    label: 'Pending tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.pendingAmount]: {
    id: Indicators.pendingAmount,
    label: 'Pending amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.withdrawTickets]: {
    id: Indicators.withdrawTickets,
    label: 'Withdraw tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Indicators.withdrawAmount]: {
    id: Indicators.withdrawAmount,
    label: 'Withdraw amount',
    unit: Units.Amount,
    type: FieldTypes.AMOUNT,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Indicators.matchRate]: {
    id: Indicators.matchRate,
    label: 'Match rate',
    unit: Units.Percent,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.withdrawRate]: {
    id: Indicators.withdrawRate,
    label: 'Withdraw rate',
    unit: Units.Percent,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher-withdraw'),
  },
  [Indicators.pendingRate]: {
    id: Indicators.pendingRate,
    label: 'Pending rate',
    unit: Units.Percent,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.allOperations]: {
    id: Indicators.allOperations,
    label: 'All operations',
    unit: Units.Operation,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
  },
  [Indicators.manuallyMatchedTickets]: {
    id: Indicators.manuallyMatchedTickets,
    label: 'Manually matched tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.automaticallyMatchedTickets]: {
    id: Indicators.automaticallyMatchedTickets,
    label: 'Automatically matched tickets',
    unit: Units.Ticket,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.OPERATION,
    seriesType: SeriesTypes.column,
    color: colorGenerator.next(),
    isForbidden: (can: (action: string, subject: string) => any) => !can('read', 'matcher'),
  },
  [Indicators.allFinancialOperations]: {
    id: Indicators.allFinancialOperations,
    label: 'All financial operations',
    unit: Units.FinancialOperation,
    type: FieldTypes.NUMBER,
    entity: TargetEntity.FINANCIAL_OPERATION,
    seriesType: SeriesTypes.line,
    color: colorGenerator.next(),
  },
};

export const list = Object.values(Indicators);

export default Indicators;
