import React, { useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Button, Space, Tag } from 'antd';
import ArrowUpBoldIcon from '@2fd/ant-design-icons/lib/ArrowUpBold';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import { useGdpr } from '../../../../../contexts/me.context';
import useSearchFilters, { useSearchFilter } from '../../hooks/useSearchFilters';
import useFieldAsyncOptionList from '../../hooks/useFieldAsyncOptionList';
import Drawers from '../constants/drawers';
import fields from '../constants/fields';
import FieldTypes from '../constants/fieldTypes';
import useDrawer from '../useDrawer';
import styles from './SearchFilterTags.module.scss';
import ListFilterTag from '../../../Common/FilterTag/ListFilterTag';
import NumberFilterTag from '../../../Common/FilterTag/NumberFilterTag';
import DateFilterTag from '../../../Common/FilterTag/DateFilterTag';
import TextFilterTag from '../../../Common/FilterTag/TextFilterTag';
import usePage from '../../hooks/usePage';
import FilterTag from '../../../Common/FilterTag/FilterTag';

const AsyncListFilterTag = ({ field, ...filterTagProps }) => {
  const { options, loading } = useFieldAsyncOptionList(field);
  return <ListFilterTag field={field} options={options} loading={loading} {...filterTagProps} />;
};

export const FilterTagBuilder = ({ field, value, excluded, onRemove }) => {
  const [drawer, setDrawer] = useDrawer();
  const page = usePage();
  const isGdpr = useGdpr();

  const disabledByEntity = !page.searchEntities.includes(field.entity);
  const disabledByGdpr = !isGdpr && field.gdpr;
  const disabled = disabledByEntity || disabledByGdpr;

  const filterTagProps = {
    id: `filter-${field.key}`,
    onClick: () => setDrawer(Drawers.SEARCH),
    onRemove: drawer !== Drawers.SEARCH && onRemove,
    label: field.label,
    displayOptions: field.displayOptions,
    filterOptions: field.filterOptions,
    excluded,
    value,
    disabled,
    title: [
      ...(disabledByEntity ? [`Not applicable on ${page.label} module.`] : []),
      ...(disabledByGdpr ? [`Not applicable without access to personal data.`] : []),
      field.description,
    ].join(' '),
  };

  if (value === '__NULL__')
    return (
      <FilterTag {...filterTagProps}>
        <Tag>Null</Tag>
      </FilterTag>
    );

  switch (field.type) {
    case FieldTypes.ASYNC_LIST:
      return <AsyncListFilterTag field={field} {...filterTagProps} />;
    case FieldTypes.LIST:
    case FieldTypes.BOOLEAN:
      return <ListFilterTag {...filterTagProps} />;
    case FieldTypes.AMOUNT:
    case FieldTypes.NUMBER:
    case FieldTypes.COUNT:
      return <NumberFilterTag {...filterTagProps} />;
    case FieldTypes.DATE:
      return <DateFilterTag {...filterTagProps} />;
    case FieldTypes.CUSTOM:
    case FieldTypes.TEXT:
      return <TextFilterTag {...filterTagProps} />;
    default:
      throw new Error(`Field type does not exist. (id: ${field.key}, type: ${field.type})`);
  }
};

const FilterTagMapper = ({ fieldKey }) => {
  const page = usePage();
  const field = { ...fields, ...page.fields }[fieldKey];
  const searchFilter = useSearchFilter(fieldKey);
  const { value, excluded } = searchFilter.value;

  const handleRemove = (e) => {
    e.stopPropagation();
    searchFilter.unset();
  };

  return <FilterTagBuilder field={field} value={value} excluded={excluded} onRemove={handleRemove} />;
};

const SearchFilterTags = ({ prefixComponent = <span>Filters</span> }) => {
  const [drawer] = useDrawer();
  const containerRef = useRef();
  const [hasOverflow, setHasOverflow] = useState(false);
  const { listAll, unsetAll, collapsed, toggleCollapsed, dateFilter } = useSearchFilters();

  useLayoutEffect(() => {
    setHasOverflow(containerRef?.current?.scrollHeight > 32);
  });

  return (
    <div className={styles.filterTagsContainer}>
      <div ref={containerRef} className={classnames(styles.collapsable, { [styles.collapsed]: collapsed })}>
        <Space size={[4, 2]} wrap>
          {prefixComponent}
          {dateFilter?.key && <FilterTagBuilder field={fields[dateFilter.key]} value={dateFilter.value} />}
          {listAll.map(({ key }) => (
            <FilterTagMapper key={key} fieldKey={key} />
          ))}
          {drawer !== Drawers.SEARCH && listAll.length > 0 && (
            <TrashCanIcon className={styles.trashIcon} onClick={() => unsetAll()} title="Remove all filters" />
          )}
        </Space>
      </div>
      {hasOverflow && (
        <Button
          className={classnames(styles.collapseButton, { [styles.collapsed]: collapsed })}
          shape="circle"
          type="default"
          onClick={() => toggleCollapsed()}
          title={collapsed ? 'Deploy filters' : 'Collapse filters'}
        >
          <ArrowUpBoldIcon />
        </Button>
      )}
    </div>
  );
};

export default SearchFilterTags;
