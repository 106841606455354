import React from 'react';
import { useTranslation } from 'react-i18next';
import REFUND_REASONS from 'norbr-shared-lib/constants/transaction/refundReasons/refundReasons';
import { Avatar, Space, Tag, Tooltip } from 'antd';
import PollIcon from '@2fd/ant-design-icons/lib/Poll';
import { useQuery } from '@apollo/client';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import styles from '../LogTable.module.scss';
import UpdateAdd from './UpdateAdd/UpdateAdd';
import UpdateEdited from './UpdateEdited/UpdateEdited';
import UpdateRemoved from './UpdateRemoved/UpdateRemoved';
import UpdateCreate from './UpdateCreate/UpdateCreate';
import UpdateDelete from './UpdateDelete/UpdateDelete';
import UpdateRuleOrder from './UpdateRuleOrder/UpdateRuleOrder';
import { getText, getValue } from './utils';
import { Link } from '../../../../../util/navigate';
import { initials } from '../../../../../util/string';
import COUNTRIES from '../../../../../constants/COUNTRIES';
import { paymentMethodsQuery } from '../../../AndMe/PaymentNetwork/RoutingRules/TestRouterDrawer/query';
import AUTHENTICATION_INDICATORS from '../../../../../constants/AUTHENTICATION_INDICATORS';
import { isModeMerchant } from '../../../../../constants/mode';
import TargetEntities, { TargetEntity } from '../../../Brainpower/Common/constants/targetEntities';

const UpdateCell = ({ log }) => {
  const { t, i18n } = useTranslation();

  const { data: { paymentMethods } = { paymentMethods: [] } } = useQuery(paymentMethodsQuery);

  // Exception for fallbackSettings rule update, to enhance later
  if (
    log.log_update?.edited?.[0]?.[0]?.includes('fallback_settings/rules') ||
    log.log_update?.added?.[0]?.[0]?.includes('fallback_settings/rules') ||
    log.log_update?.removed?.[0]?.[0]?.includes('fallback_settings/rules')
  ) {
    return (
      <>
        Edited:
        <br />
        {isModeMerchant ? (
          <Link to={`/router/optimizer/sca-rules?maccount=${log.log_merchant_account_id}`}>
            Fallback settings rules
          </Link>
        ) : (
          'Fallback settings rules'
        )}
      </>
    );
  }

  switch (log.type) {
    case 'cancel':
      return (
        <>
          Cancelled order <Link to={`/brainpower/orders/${log.log_entity_id}`}>{log.log_entity_id}</Link>
        </>
      );
    case 'capture':
      return (
        <>
          Captured order <Link to={`/brainpower/orders/${log.log_entity_id}`}>{log.log_entity_id}</Link>
          <br />
          Amount: {log.log_update.amount} {log.log_update.currency}
        </>
      );
    case 'create':
      return <UpdateCreate log={log} />;
    case 'delete':
      return <UpdateDelete log={log} />;
    case 'locked':
      return 'User locked after 3 failed password attempts';
    case 'refund':
      return (
        <>
          Refunded order <Link to={`/brainpower/orders/${log.log_entity_id}`}>{log.log_entity_id}</Link>
          <br />
          Reason: {REFUND_REASONS[log.log_update.reason]?.label ?? 'n/a'}
          {log.log_update.input.map((refund) => (
            <>
              <br />
              Amount: {refund.amount} {log.log_update.currency}
            </>
          ))}
        </>
      );
    case 'resetPassword':
      return (
        <>
          Reset password for user:
          <br />
          {isModeMerchant ? (
            <Link to={`/teams/users/${log.log_entity_id}`}>{log.log_entity_name}</Link>
          ) : (
            <Link to={`/users/${log.log_entity_id}`}>{log.log_entity_name}</Link>
          )}
        </>
      );
    case 'signin':
      return `The user has successfully logged in${log.log_update?.signinType ? ` using ${log.log_update?.signinType}` : ''}`;
    case 'update':
      return (
        <div className={styles.updateCell}>
          {!!log.log_update.added?.length && <UpdateAdd log={log} />}
          {!!log.log_update.edited?.length && <UpdateEdited log={log} />}
          {!!log.log_update.removed?.length && <UpdateRemoved log={log} />}
        </div>
      );
    case 'updateListContent':
      if (log.log_update.added) {
        return (
          <>
            Added to list content:
            <br />
            {log.log_update.added.join(',')}
          </>
        );
      }
      if (log.log_update.removed) {
        return (
          <>
            Removed from list content:
            <br />
            {log.log_update.removed.join(',')}
          </>
        );
      }
      if (log.log_update.replaced) {
        return (
          <>
            List content replaced with:
            <br />
            {log.log_update.replaced.join(',')}
          </>
        );
      }
      return null;
    case 'updateCapture':
      if (log.log_update.status === 'inactive') {
        return 'Schedule capture changed to Capture on demand';
      }
      return `Schedule capture activated with a delay of ${log.log_update.delay} minutes`;
    case 'updateRoutes':
      return (
        <>
          {log.log_update.removed && 'Removed'}
          {log.log_update.added && 'Added'} on{' '}
          {log.log_update.paymentMethod
            ? getValue('payment_method', 'MerchantContract', t, i18n, log.log_update.paymentMethod)
            : log.log_update.paymentMethods
                .map((paymentMethod) => getValue('payment_method', 'MerchantContract', t, i18n, paymentMethod))
                .join(', ')}
          :
          <br />
          {getText(log.log_update?.added?.[0] ?? log.log_update?.removed?.[0], log.log_entity_type, t)}:{' '}
          {getValue(
            log.log_update?.added?.[0] ?? log.log_update?.removed?.[0],
            log.log_entity_name,
            t,
            i18n,
            log.log_update?.added?.[1] ?? log.log_update?.removed?.[1],
          )}
        </>
      );
    case 'updateRule': {
      const v = log.log_update.edited.find((edit) => edit[0] === '__v');
      if (!v) return `The rule ${log.log_entity_name} was saved without changes`;
      return isModeMerchant ? (
        <>
          New version of the rule {log.log_update.name}:{' '}
          <Link to={`/router/payment-network/rule/${log.log_entity_id}/${v[2]}`}>version {v[2]}</Link> (previous version
          was <Link to={`/router/payment-network/rule/${log.log_entity_id}/${v[1]}`}>version {v[1]}</Link>)
        </>
      ) : (
        <>
          New version of the rule {log.log_update.name}: version {v[2]} (previous version was version {v[1]})
        </>
      );
    }
    case 'updateSso': {
      return (
        <>
          <Link to="/sso">SSO configuration</Link> updated for company {log.log_entity_name}
        </>
      );
    }
    case 'updateLoginMethod': {
      return (
        <>
          <Link to="/sso">Login method</Link> updated for company {log.log_entity_name} from{' '}
          {log.log_update?.edited?.[0]?.[1]} to {log.log_update?.edited?.[0]?.[2]}
        </>
      );
    }
    case 'updateRuleOrder': {
      return <UpdateRuleOrder log={log} />;
    }
    case 'wrongPassword':
      return 'The user entered an incorrect password';
    case 'shareDashboardToUser':
      return (
        <Space>
          <Avatar size="small" src={log.log_update.from.avatar}>
            {initials(log.log_update.from.fullName)}
          </Avatar>
          <span title={log.log_update.from.email}>{log.log_update.from.fullName}</span>
          shared the dashboard
          <Tag icon={<PollIcon />}>{log.log_update.dashboard.name}</Tag>
          to
          <Avatar.Group maxCount={10} size="small" style={{ display: 'block' }}>
            {Array.isArray(log.log_update.to) &&
              log.log_update.to.map((u) => (
                <Tooltip title={`${u.fullName} (${u.email})`} placement="top">
                  <Avatar size="small" src={u.avatar}>
                    {initials(u.fullName)}
                  </Avatar>
                </Tooltip>
              ))}
          </Avatar.Group>
        </Space>
      );
    case 'shareCompanyDashboard':
      return (
        <Space>
          <Avatar size="small" src={log.log_update.from.avatar}>
            {initials(log.log_update.from.fullName)}
          </Avatar>
          <span title={log.log_update.from.email}>{log.log_update.from.fullName}</span>
          shared the dashboard
          <Tag icon={<PollIcon />}>{log.log_update.dashboard.name}</Tag>
          as company dashboard for
          <Avatar size="small" src={log.log_update.to.icon}>
            {initials(log.log_update.to.name)}
          </Avatar>
          <span>{log.log_update.to.name}</span>
        </Space>
      );
    case 'deleteCompanyDashboard':
      return (
        <Space>
          <Avatar size="small" src={log.log_update.from.avatar}>
            {initials(log.log_update.from.fullName)}
          </Avatar>
          <span title={log.log_update.from.email}>{log.log_update.from.fullName}</span>
          deleted the company dashboard
          <Tag icon={<PollIcon />}>{log.log_update.dashboard.name}</Tag>
        </Space>
      );
    case 'testRouter':
      return (
        <div>
          Routing tested with parameters:
          <br />
          <br />
          {Object.keys(log.log_update.input)
            .sort((a, b) => (t(`testRouter.${b}`).toLowerCase() > t(`testRouter.${a}`).toLowerCase() ? -1 : 1))
            .map((field) => {
              const value = log.log_update.input[field];
              const fieldComponent = (v) => (
                <div>
                  {t(`testRouter.${field}`)}: {v}
                </div>
              );
              switch (field) {
                case 'card_issuer':
                  if (value !== 'UNKNOWN') {
                    return fieldComponent(value);
                  }
                  break;
                case 'basket_content':
                  if (value.length) {
                    return fieldComponent(
                      <ul>
                        {value.map((data) => (
                          <li>{data.product_sku}</li>
                        ))}
                      </ul>,
                    );
                  }
                  break;
                case 'merchant_data':
                  if (value.length) {
                    return fieldComponent(
                      <ul>
                        {value.map((data) => (
                          <li>
                            {data.key} ({data.type}): {data.value}
                          </li>
                        ))}
                      </ul>,
                    );
                  }
                  break;
                case 'card_country':
                case 'customer_ip_country':
                  if (value !== 'XX') {
                    return fieldComponent(`${COUNTRIES[value].label} (${value})`);
                  }
                  break;
                case 'payment_channel':
                  if (value) {
                    return fieldComponent(t(`andMe.paymentNetwork.merchantContract.channels.${value}`));
                  }
                  break;
                case 'payment_method_name':
                  if (value) {
                    return fieldComponent(paymentMethods?.find((pm) => pm.id === value)?.name ?? value);
                  }
                  break;
                case 'payment_method_type':
                  if (value) {
                    return fieldComponent(
                      t(
                        `andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.paymentMethodType.options.${value}`,
                      ),
                    );
                  }
                  break;
                case 'currency':
                  if (value) {
                    return fieldComponent(`[${value}] ${CURRENCIES[value].label} (${CURRENCIES[value].symbol})`);
                  }
                  break;
                case 'authentication_indicator':
                  if (value) {
                    return fieldComponent(AUTHENTICATION_INDICATORS[value].label);
                  }
                  break;
                case 'card_6_digits':
                case 'merchant_store_id':
                case 'customer_id':
                case 'customer_email':
                case 'customer_ip':
                case 'customer_zip_code':
                case 'reference_currency_amount':
                  if (value) {
                    return fieldComponent(value);
                  }
                  break;
                case 'company_id':
                case 'merchant_account':
                default:
                  return null;
              }
              return null;
            })}
        </div>
      );
    case 'reconcileTransaction':
      // handle historic logs, now reconciliation is on operations
      // eslint-disable-next-line no-case-declarations
      const { matchedId, matchedIcon, matchedType } = log.log_update.operationId
        ? {
            matchedId: log.log_update.operationId,
            matchedIcon: TargetEntities[TargetEntity.OPERATION].icon(),
            type: 'operation',
          }
        : {
            matchedId: log.log_update.transactionId,
            matchedIcon: TargetEntities[TargetEntity.TRANSACTION].icon(),
            type: 'transaction',
          };

      switch (log.log_update.reconciliation_type) {
        case 'manual':
          return (
            <Space>
              <Avatar size="small" src={log.log_update.from.avatar}>
                {initials(log.log_update.from.fullName)}
              </Avatar>
              <span title={log.log_update.from.email}>{log.log_update.from.fullName}</span>
              matched the {matchedType}
              <Tag icon={matchedIcon}>{matchedId}</Tag>
              with the notification
              <Tag icon={TargetEntities[TargetEntity.NOTIFICATION].icon()}>{log.log_update.pspOperationId ?? log.log_update.pspTransactionId}</Tag>
              {`with the reason "${log.log_update.reason}"`}
            </Space>
          );
        case 'withdraw':
          return (
            <Space>
              <Avatar size="small" src={log.log_update.from.avatar}>
                {initials(log.log_update.from.fullName)}
              </Avatar>
              <span title={log.log_update.from.email}>{log.log_update.from.fullName}</span>
              marked the {matchedType}
              <Tag icon={matchedIcon}>{matchedId}</Tag>
              as withdrawn
              {`with the reason "${log.log_update.reason}"`}
            </Space>
          );
        case 'pending':
          return (
            <Space>
              <Avatar size="small" src={log.log_update.from.avatar}>
                {initials(log.log_update.from.fullName)}
              </Avatar>
              <span title={log.log_update.from.email}>{log.log_update.from.fullName}</span>
              marked the {matchedType}
              <Tag icon={matchedIcon}>{matchedId}</Tag>
              as to match later
              {`with the reason "${log.log_update.reason}"`}
            </Space>
          );
        default:
          console.log('reconciliation type not recognized');
          return null;
      }
    case 'close':
      return (
        <Space>
          <Avatar size="small" src={log.log_update.author.avatar}>
            {initials(log.log_update.author.fullName)}
          </Avatar>
          <span title={log.log_update.author.email}>{log.log_update.author.fullName}</span>
          closed the register for {log.log_merchant_account_name} on {log.log_entity_id.operation_date}
        </Space>
      );
    default:
      return 'Log not handled';
  }
};

export default UpdateCell;
