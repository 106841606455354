import React, { useState } from 'react';
import { Button, Form, Input, Select, Space, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { difference } from '../../../../../../util/array';
import InfoTile, {InfoBooleanRow, InfoTextRow} from '../../../../Brainpower/OrderDetail/Tile/Info/Info';
import styles from '../ProgramManagerCompanyExecutive/ProgramManagerCompanyExecutive.module.scss';
import { useCan } from '../../../../../../contexts/ability.context';
import ImpactedUsers from './ImpactedUsers';
import { programManagerOptionsQuery, updateProgramManagerOptionsMutation } from './query';
import {MODES, useMode} from '../../../../../../contexts/mode.context';

const ProgramManagerOptions = ({ id }) => {
  const can = useCan();
  const [form] = Form.useForm();
  const mode = useMode();

  const [editMode, setEditMode] = useState(false);
  const [emailDomains, setEmailDomains] = useState();

  const { data } = useQuery(programManagerOptionsQuery, { variables: { id } });

  const programManager = data?.programManager;

  const [updateOptions, { loading }] = useMutation(updateProgramManagerOptionsMutation, { variables: { id } });

  // return edit form tile
  if (editMode) {
    return (
      <InfoTile name="settings" title="Settings">
        <Form
          form={form}
          title="Options"
          layout="vertical"
          initialValues={programManager}
          onFinish={(input) =>
            updateOptions({
              variables: {
                input: {
                  displayName: input.displayName,
                  code: input.code,
                  meta_token: input.metaToken,
                  website: input.website,
                  notificationUrl: input.notificationUrl,
                  apiDomain: input.apiDomain,
                  emailDomains: input.emailDomains,
                  ...([MODES.INTERNAL, MODES.META_PM].includes(mode) ? {displayPartners: input.displayPartners} : {})
                },
              },
            }).then(() => setEditMode(false))
          }
          requiredMark={false}
          className="tile-form"
        >
          <Form.Item
            label="Display name"
            name="displayName"
            rules={[{ required: true }]}
            tooltip="Name displayed on the merchant console."
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true }, { len: 4, message: 'Code should have 4 characters.' }]}
            tooltip="Technical code (e.g. used to prefix API keys like XXXX_PR_123456789...)."
          >
            <Input />
          </Form.Item>
          <Form.Item name="merchantDomain" label="Merchant domain" tooltip="Domain of merchant application.">
            <Input disabled />
          </Form.Item>
          <Form.Item name="adminDomain" label="Admin domain" tooltip="Domain of admin application.">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="alternativeMerchantDomain"
            label="Alternative merchant domain"
            tooltip={
              <>
                Alternative environment domain of merchant application.
                <br />
                Production / Sandbox
                <br />
                Staging / Development
              </>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="alternativeAdminDomain"
            label="Alternative admin domain"
            tooltip={
              <>
                Alternative environment domain of admin application.
                <br />
                Production / Sandbox
                <br />
                Staging / Development
              </>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="apiDomain"
            label="API domain"
            tooltip="Only used to route vanity urls."
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Authorized email domains"
            name="emailDomains"
            tooltip="Authorized domains for users who access this Back Office. You can create new users based on this list of domains only. If a domain is deleted, users will not be able to connect anymore."
            onReset={() => setEmailDomains(undefined)}
          >
            <Select mode="tags" style={{ width: '100%' }} onChange={setEmailDomains} />
          </Form.Item>
          {emailDomains && (
            <ImpactedUsers programManager={id} emailDomains={difference(programManager.emailDomains, emailDomains)} />
          )}
          <Form.Item label="Meta token" name="metaToken" tooltip="Does the program manager support a meta token?">
            <Switch defaultChecked={!!programManager?.metaToken} disabled />
          </Form.Item>
          <Form.Item name="notificationUrl" label="Notification URL">
            <Input />
          </Form.Item>
          <Form.Item name="website" label="Website" tooltip="URL display as back link on login page.">
            <Input />
          </Form.Item>
          {[MODES.INTERNAL, MODES.META_PM].includes(mode) && (
            <Form.Item label="Display available partners" name="displayPartners" tooltip="Does the program manager see available partners?">
              <Switch defaultChecked={programManager?.displayPartners} />
            </Form.Item>
          )}
          <Form.Item className={styles.submitItem}>
            <Space>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
              <Button onClick={() => form.resetFields()}>Reset</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </InfoTile>
    );
  }

  // return info tile
  return (
    <InfoTile
      name="settings"
      title="Settings"
      extra={
        can('update', 'program-manager') && (
          <Button onClick={() => setEditMode(true)} shape="text" icon={<EditOutlined />} />
        )
      }
    >
      <InfoTextRow label="Display name" value={programManager?.displayName} />
      <InfoTextRow label="Code" value={programManager?.code} />
      <InfoTextRow label="Merchant app domain" value={programManager?.merchantDomain} />
      <InfoTextRow label="Admin app domain" value={programManager?.adminDomain} />
      <InfoTextRow label="Alternative merchant app domain" value={programManager?.alternativeMerchantDomain} />
      <InfoTextRow label="Alternative admin app domain" value={programManager?.alternativeAdminDomain} />
      <InfoTextRow label="API domain" value={programManager?.apiDomain} />
      <InfoTextRow label="Authorized email domains" value={programManager?.emailDomains?.join(', ')} />
      <InfoTextRow label="Meta token" value={programManager?.metaToken ? 'True' : 'false'} />
      <InfoTextRow label="Notification URL" value={programManager?.notificationUrl} />
      <InfoTextRow label="Website" value={programManager?.website} />
      {[MODES.INTERNAL, MODES.META_PM].includes(mode) && <InfoBooleanRow label="Display available partners" value={programManager?.displayPartners} />}
    </InfoTile>
  );
};

export default ProgramManagerOptions;
